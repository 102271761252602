import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../components/context/window-width";
import { FooterColor } from "../../components/context/footer-color";

// Components
import StoreContext from "../../components/context/store";
import Layout from "../../components/account/Layout";
import OrdersList from "../../components/account/orders-list";
import { MobileAccountNavigation } from "../../components/account/mobile-account-navigation";
import { AccountNavigation } from "../../components/account/account-navigation";
import { Spacer } from "../../components/utils/spacer";

import {
	Page,
	AccountContainer,
	ContentContainer,
} from "../../components/trade/trade-components";
import { tertiary } from "../../components/utils/colors";

const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			phone
			defaultAddress {
				firstName
				lastName
				address1
				city
				zip
				country
			}
			orders(first: 50, reverse: true) {
				edges {
					node {
						name
						totalPrice
						processedAt
						statusUrl
						currencyCode
						subtotalPrice
						totalPriceV2 {
							amount
							currencyCode
						}
						discountApplications(first: 10, reverse: true) {
							edges {
								node {
									value {
										... on MoneyV2 {
											amount
											currencyCode
										}
										... on PricingPercentageValue {
											percentage
										}
									}
									targetType
									targetSelection
									allocationMethod
									... on DiscountCodeApplication {
										value {
											... on MoneyV2 {
												amount
												currencyCode
											}
											... on PricingPercentageValue {
												percentage
											}
										}
									}
								}
							}
						}
						totalShippingPriceV2 {
							amount
							currencyCode
						}
						subtotalPriceV2 {
							amount
							currencyCode
						}
						lineItems(first: 10) {
							edges {
								node {
									title
									quantity

									variant {
										price
										title
										image {
											originalSrc
										}
									}
								}
							}
						}
						shippingAddress {
							address1
							city
							lastName
							firstName
							zip
							country
						}
					}
				}
			}
			addresses(first: 10) {
				edges {
					node {
						address1
						city
						lastName
						firstName
						country
						name
						zip
					}
				}
			}
		}
	}
`;

const Orders = () => {
	const { customerAccessToken } = useContext(StoreContext);
	const windowWidth = useContext(WindowWidthContext);

	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<Layout>
			<Helmet title={`Orders | Christian Watson`} />

			<Query
				query={CUSTOMER_INFO}
				variables={{
					customerAccessToken: customerAccessToken.accessToken,
				}}
				fetchPolicy="no-cache"
			>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<Page>
								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>
								<AccountContainer>
									<ContentContainer>
										<p className="margin-0">Fetching</p>
									</ContentContainer>
								</AccountContainer>
							</Page>
						);
					}
					if (error) {
						return (
							<Page>
								<Media
									queries={{
										medium: "(min-width: 1025px)",
									}}
									defaultMatches={{
										medium: windowWidth === 1025,
									}}
									render={() => (
										<AccountNavigation position={`relative`} top={0} />
									)}
								/>
								<AccountContainer>
									<ContentContainer>
										<p className="margin-0">
											Error loading page. Please try refreshing.
										</p>
									</ContentContainer>
								</AccountContainer>
							</Page>
						);
					}

					const { orders } = data.customer;

					return (
						<Page>
							<Media
								queries={{
									medium: "(min-width: 1025px)",
								}}
								defaultMatches={{
									medium: windowWidth === 1025,
								}}
								render={() => (
									<AccountNavigation position={`relative`} top={0} />
								)}
							/>

							<Media
								queries={{
									medium: "(max-width: 1024px)",
								}}
								defaultMatches={{
									medium: windowWidth === 1024,
								}}
								render={() => (
									<MobileAccountNavigation position={`relative`} top={0} />
								)}
							/>

							<AccountContainer>
								<ContentContainer>
									{orders.edges.length >= 1 ? (
										<OrdersList orders={orders} windowWidth={windowWidth} />
									) : (
										<div className="text-column">
											<h1 className="caps">Order History</h1>
											<p>
												Here you will be able to see all of your previous orders
												placed while logged in to your account.
											</p>

											<div className="continue-shopping">
												<p>
													<Link to={"/furniture"} className="link">
														View Our Furniture
													</Link>
												</p>
											</div>
										</div>
									)}
								</ContentContainer>
								<Spacer />
							</AccountContainer>
						</Page>
					);
				}}
			</Query>
		</Layout>
	);
};

export default Orders;
