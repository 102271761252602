import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Styles
import { secondaryDark } from "../utils/colors";

const SingleOrderRow = styled.div`
	text-align: left;

	& .headings {
		width: 100%;
		line-height: 1;
	}

	& .border-bottom {
		border-bottom: 1px solid ${secondaryDark};
		padding: 0 0 9px 0;
	}

	& button {
		margin: 20px 0 0 0;
	}

	& p {
		margin: 0;
	}
`;

const GridRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;

	margin: 0 0 9px 0;
`;

const InnerGridRow = styled(GridRow)`
	padding: 9px 0;

	border-bottom: 1px solid ${secondaryDark};
`;

const OrderDetails = styled.div`
	display: ${(props) => props.display};

	& .shipping-details {
		margin: 2em 0 1em 0;
	}
`;

const Line = styled.hr`
	width: 100%;
	margin: 15px 0;

	border: 0;
	border-bottom: 1px solid ${secondaryDark};

	&:last-of-type {
		display: none;
	}
`;

export const OrderMobile = ({ order }) => {
	const [selectedOrder, setSelectedOrder] = useState(false);
	const [checkoutHasDiscount, setCheckoutHasDiscount] = useState(null);
	const [discount, setDiscount] = useState(0);

	const dateOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	useEffect(() => {
		setCheckoutHasDiscount(
			order.discountApplications.edges.length >= 1 ? true : false
		);
	}, [order, setCheckoutHasDiscount]);

	useEffect(() => {
		if (checkoutHasDiscount !== null && checkoutHasDiscount !== false) {
			setDiscount(order.discountApplications.edges[0].node.value.percentage);
		}
	}, [checkoutHasDiscount]);

	return (
		<>
			<SingleOrderRow key={order.name}>
				<div className="headings">
					<GridRow>
						<p className="caps">Order No.</p>
						<p>{order.name}</p>
					</GridRow>
					<GridRow>
						<p className="caps">Date</p>
						<p>
							{new Date(order.processedAt).toLocaleDateString(
								"en-GB",
								dateOptions
							)}
						</p>
					</GridRow>

					<GridRow>
						<p className="caps">Total</p>
						<p>£{order.totalPrice}</p>
					</GridRow>

					<button onClick={() => setSelectedOrder(!selectedOrder)}>
						{selectedOrder === true ? `Hide Order` : `View Order`}
					</button>
				</div>

				<OrderDetails
					className="order-details"
					display={selectedOrder === true ? `block` : `none`}
				>
					<div className="shipping-details">
						<div className="shipping-container">
							<p className="caps">Shipping Address</p>

							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.firstName}{" "}
								{order.shippingAddress !== null &&
									order.shippingAddress.lastName}
							</p>

							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.address1}
							</p>

							<p>
								{order.shippingAddress !== null && order.shippingAddress.city}{" "}
								{order.shippingAddress !== null && order.shippingAddress.zip}
							</p>

							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.country}
							</p>
						</div>
					</div>

					<div className="headings border-bottom">
						<div className="caps">
							<p>Item</p>
						</div>
					</div>

					{order.lineItems &&
						order.lineItems.edges.map((lineItem, index) => (
							<InnerGridRow
								key={`${lineItem.node.title}-${order.processedAt}-${index}`}
							>
								<div className="image-container">
									{lineItem.node.variant !== null && (
										<img
											src={lineItem.node.variant.image.originalSrc}
											alt={lineItem.node.title}
											loading="lazy"
										/>
									)}
								</div>

								<div>
									<p>{lineItem.node.title}</p>

									{lineItem.node.variant !== null && (
										<p>{lineItem.node.variant.title}</p>
									)}

									{lineItem.node.variant !== null && (
										<p>
											{lineItem.node.quantity} × £{lineItem.node.variant.price}
										</p>
									)}
								</div>
							</InnerGridRow>
						))}

					<div className="totals">
						{checkoutHasDiscount && (
							<GridRow className="row">
								<div>
									<p className="caps">Trade Discount</p>
								</div>
								<div>
									<p>{discount}%</p>
								</div>
							</GridRow>
						)}

						<GridRow className="row">
							<div>
								<p className="caps">Order Total</p>
							</div>
							<div>
								<p>£{order.totalPrice}</p>
							</div>
						</GridRow>
					</div>
				</OrderDetails>
			</SingleOrderRow>
			<Line />
		</>
	);
};
