import React from "react";
import Media from "react-media";
import styled from "styled-components";

// Components
import { OrderDesktop } from "./order-desktop";
import { OrderMobile } from "./order-mobile";

const DesktopOrdersHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;

	border-bottom: 1px solid black;
	margin: 0 auto 10px auto;

	padding: 0 0 10px 0;

	& .qty-spacer {
		padding: 0 0 0 10px;
	}

	& p {
		margin: 0;
	}
`;

const OrdersList = ({ orders, windowWidth }) => (
	<>
		<h1 className="caps">Order History</h1>
		{orders.edges.length === 0 ? (
			<p>You haven't placed any orders yet.</p>
		) : (
			<>
				<Media
					queries={{
						medium: "(min-width: 650px)",
					}}
					defaultMatches={{
						medium: windowWidth === 650,
					}}
					render={() => (
						<div>
							<DesktopOrdersHeader>
								<div className="caps">
									<p>Order No.</p>
								</div>
								<div className="caps">
									<p>Date</p>
								</div>
								<div className="caps">
									<p>Total</p>
								</div>
								<div className="caps">
									<p>Details</p>
								</div>
							</DesktopOrdersHeader>

							{orders.edges.map((order) => (
								<OrderDesktop key={order.node.name} order={order.node} />
							))}
						</div>
					)}
				/>

				<Media
					queries={{
						medium: "(max-width: 649px)",
					}}
					defaultMatches={{
						medium: windowWidth === 649,
					}}
					render={() => (
						<>
							{orders.edges.map((order) => (
								<OrderMobile key={order.node.name} order={order.node} />
							))}
						</>
					)}
				/>
			</>
		)}
	</>
);

export default OrdersList;
