import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Styles
import { secondaryDark, secondaryLight } from "../utils/colors";

const SingleOrderRow = styled.div`
	text-align: left;

	border-bottom: 1px solid ${secondaryDark};

	& .headings {
		width: 100%;
		line-height: 1;

		border-bottom: 1px solid ${secondaryDark};
		padding: 0 0 9px 0;
	}

	& .border-bottom {
		border-bottom: 1px solid ${secondaryDark};
		padding: 0 0 9px 0;
	}

	& button {
		&:hover {
			color: ${secondaryLight};
		}
	}

	& p {
		margin: 0;
	}
`;

const OrderDetails = styled.div`
	display: ${(props) => props.display};

	& .shipping-details {
		margin: 2em 0 1em 0;

		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 20px;

		& .shipping-container {
			grid-column: 1 / 3;

			& .title {
				margin: 0 0 5px 0;
			}
		}
	}

	& .totals {
		border-top: 1px solid ${secondaryDark};

		padding: 4px 0;

		& .row {
			margin: 5px 0;
		}

		& .title {
			grid-column: 3;
		}
	}
`;

const GridRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;

	margin: 9px 0;

	& button {
		text-align: left;
	}

	& .image-container {
		grid-column: 1;
	}

	& .text-container {
		grid-column: 2 / 4;
	}

	& .span-3 {
		grid-column: 1/4;
	}

	& .grid-column-start-3 {
		grid-column-start: 3;
	}
`;

export const OrderDesktop = ({ order }) => {
	const [selectedOrder, setSelectedOrder] = useState(false);
	const [checkoutHasDiscount, setCheckoutHasDiscount] = useState(null);
	const [discount, setDiscount] = useState(0);

	const dateOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	useEffect(() => {
		setCheckoutHasDiscount(
			order.discountApplications.edges.length >= 1 ? true : false
		);
	}, [order, setCheckoutHasDiscount]);

	useEffect(() => {
		if (checkoutHasDiscount !== null && checkoutHasDiscount !== false) {
			setDiscount(order.discountApplications.edges[0].node.value.percentage);
		}
	}, [checkoutHasDiscount]);

	return (
		<SingleOrderRow>
			<div
				key={`${order.name}-${order.processedAt}`}
				className="single-order-row"
			>
				<GridRow>
					<div>
						<p>{order.name}</p>
					</div>
					<div>
						<p>
							{new Date(order.processedAt).toLocaleDateString(
								"en-GB",
								dateOptions
							)}
						</p>
					</div>
					<div>
						<p>£{order.totalPrice}</p>
					</div>

					<div>
						<button onClick={() => setSelectedOrder(!selectedOrder)}>
							{selectedOrder === true ? `Hide Order` : `View Order`}
						</button>
					</div>
				</GridRow>

				<OrderDetails
					className="order-details"
					display={selectedOrder === true ? `block` : `none`}
				>
					<div className="shipping-details">
						<div className="shipping-container">
							<div className="shipping-address caps">
								<p>Shipping Address</p>
							</div>
							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.firstName}{" "}
								{order.shippingAddress !== null &&
									order.shippingAddress.lastName}
							</p>
							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.address1}
							</p>
							<p>
								{order.shippingAddress !== null && order.shippingAddress.city}
							</p>
							<p>
								{order.shippingAddress !== null &&
									order.shippingAddress.country}{" "}
								{order.shippingAddress !== null && order.shippingAddress.zip}
							</p>
						</div>
					</div>

					<GridRow className="headings">
						<div className="span-3 caps">
							<p>ITEM</p>
						</div>
						<div className="caps">
							<p>PRICE</p>
						</div>
					</GridRow>

					{order.lineItems &&
						order.lineItems.edges.map((lineItem, index) => (
							<GridRow
								key={`${lineItem.node.title}-${order.processedAt}-${index}`}
							>
								<div className="image-container">
									{lineItem.node.variant !== null && (
										<img
											src={lineItem.node.variant.image.originalSrc}
											alt={lineItem.node.title}
											loading="lazy"
										/>
									)}
								</div>
								<div className="text-container">
									<p>{lineItem.node.title}</p>
									{lineItem.node.variant !== null && (
										<p>{lineItem.node.variant.title}</p>
									)}
								</div>

								{lineItem.node.variant !== null && (
									<div>
										<p>
											{lineItem.node.quantity} × £{lineItem.node.variant.price}
										</p>
									</div>
								)}
							</GridRow>
						))}

					<div className="totals">
						{checkoutHasDiscount && (
							<GridRow className="row">
								<div className="grid-column-start-3">
									<p className="caps">Trade Discount</p>
								</div>
								<div>{discount}%</div>
							</GridRow>
						)}

						<GridRow className="row">
							<div className="grid-column-start-3">
								<p className="caps">Order Total</p>
							</div>
							<div>
								<p>£{order.totalPrice}</p>
							</div>
						</GridRow>
					</div>
				</OrderDetails>
			</div>
		</SingleOrderRow>
	);
};
